import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import styled from "styled-components";
import MobileBgPattern from "common/assets/image/faq/mobile-bg.svg";
import bgPattern from "common/assets/image/faq/faq-bg.svg";
import Button from "common/components/Button";
import Box from "common/components/Box";
import { flexDirection } from "styled-system";

const Section = styled.section`
  padding: 100px 0 135px;
  @media (max-width: 990px) {
    padding-top: 75px;
    padding-bottom: 90px;
    background-size: contain;
    background-repeat: repeat;
  }
  @media (max-width: 568px) {
    background-size: cover;
    padding-top: 45px;
    padding-bottom: 80px;
  }

  .container {
    padding: 100px 40px;
    background: #d1d9de url(${bgPattern}) no-repeat center;
    position: relative;
    background-size: cover;
    @media (max-width: 990px) {
      padding: 70px 0;
    }
    @media (max-width: 568px) {
      background: #d1d9de url(${MobileBgPattern}) no-repeat center;
      padding: 40px 0;
      background-size: cover;
    }
  }
`;

const AuditDefenseProgram = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="Audit Defense Program" />
      <Section>
        <Container>
          <Box flexDirection={"row"}>
            <Heading as="h1" content={"Audit Defense Program"} />
            <Button
              title="Report IRS Notice"
              variant="textButton"
              onClick={() => window.location = "/report-irs-notice"}
              iconPosition="left"
            />
          </Box>
          <p></p>
          <Heading as="h3" content={"ERT.Credit 3-Year AUDIT DEFENSE MEMBERSHIP AGREEMENT"} />
          <Text content="ERT.Credit will provide audit defense services for the amended payroll tax return data and calculations delivered to the client in return for the applicable membership fee and compliance with all applicable terms of this agreement herein referred to as the (Audit Defense Plan)" />
          <h3>CLARIFICATION OF TERMS</h3>
          <p>In this agreement, &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the enterprise protected by this agreement. &ldquo;We,&rdquo; and &ldquo;us" refer to ERT.Credit, the company providing audit defense services. &ldquo;IRS&rdquo; refers to the Internal Revenue Service and &ldquo;State&rdquo; refers to your state income tax authority.</p>
          <ul>
            <li><strong>Audit:</strong> Audit means any communication, including telephone calls, initiated by the IRS or State income tax agency that wishes to audit, examine, review, investigate or verify any item or items on the IRS income tax forms and State equivalent listed on the membership certificate.</li>
            <li><strong>Audit Defense:</strong> Audit Defense means that we will represent you through the completion of any tax audit for the amended tax return year identified by this agreement during the Period of Membership. Audit notification must be received during the Period of Membership. The Audit Defense Plan is also subject to the limitations and exclusions listed in this agreement. Audit Defense also includes:</li>
            <ol>
              <li>Handling all communications, including letters and/or telephone calls with the IRS or State regarding the audit.</li>
              <li>Assigning the Audit Representative(s) to manage your case.</li>
              <li>Developing a strategy with you and then meeting or corresponding with the IRS or State on your behalf.</li>
              <li>Negotiating with the IRS or State through Appeals and pre-litigation Appeals review prior to trial in Tax Court.</li>
              <li>No settlement will be reached with the IRS or State without your final approval and consent.</li>
              <li>Two hours of collection assistance is available on request if your covered audit results in additional tax due.</li>
            </ol>
            <li><strong>Acceptance Date:</strong> Acceptance Date is the date that ERT.Credit receives your payment for the Audit Defense Plan in full. Your Acceptance Date appears on the membership certificate.</li>
            <li><strong>Statute of Limitations:</strong> Statute of Limitations is the time the IRS or State has to audit your tax return. The Statute of Limitations for the IRS is typically three years from the date of filing or the due date, whichever is later, and is typically four years for States.</li>
            <li><strong>Expiration Date of Membership:</strong> The Expiration Date of Membership is based on the Acceptance Date. The expiration date of this program is precisely 3 years from the Acceptance Date. Member may choose to extend their audit defense coverage for an additional two years on or before the Expiration Date.</li>
            <li><strong>Period of Membership:</strong> Period of Membership is the period commencing with the Acceptance Date and ending with the Expiration Date of Membership or the expiration of the typical Statute of Limitations period for the applicable tax return, whichever occurs first.</li>
            <li><strong>Audit Representative(s):</strong> Audit Representative(s) means your ERT.Credit audit representative(s) who will be assigned to your audit case. These individuals will ordinarily be assigned to you according to their area of expertise.</li>
          </ul>
          <h3>CONFIDENTIALITY</h3>
          <p>Your name, address, and any other personal information will not be disclosed or sold to any persons or firms. Only ERT.Credit&rsquo;s staff will have access to your tax information.</p>
          <h3>MEMBER BENEFITS</h3>
          <p><strong>Audit Defense</strong> - ERT.Credit will professionally defend a covered federal 941-X audit or notice from the time of the first notice to its completion, subject to the Audit Defense Plan Limitations and Exclusions described below. This includes any Audit that arises during the Period of Membership. All scheduling of appointments, telephone calls and correspondence will be handled by the assigned Audit Representative(s). We will meet or correspond with the auditor on your behalf and will defend you through the highest level of appeals, subject to the terms herein.</p>
          <h3>RESPONSIBILITIES</h3>
          <ul>
            <li><strong>Our Responsibility:</strong> We are responsible to provide you with professional Audit Defense service.</li>
            <li><strong>Your Responsibility:</strong> Upon receipt of any communication from the IRS or State, you are responsible to perform or provide the following:</li>
            <ol>
              <li><strong><em>Promptly</em></strong> report your notice at <a href="https://ert.credit/report-irs-notice" target="_blank">https://ert.credit/audit-report</a>. <strong>Do not contact the IRS or State.</strong> To ensure effective service regarding your Audit, <strong><em>you must</em></strong> use your assigned ERT.Credit Audit Representative(s) as your <strong><em>only</em></strong> contact with the IRS or State. <strong>If you do not contact ERT.Credit within 15 days of the date of the first notice, additional charges may apply. If you wait too long, you will eliminate our ability to defend your case.</strong></li>
              <li>Provide your signature(s) on the required IRS Power of Attorney and return to the Audit Representative(s) in a timely manner. This will enable your Audit Representative(s) to communicate with the IRS or State on your behalf. This document may not be altered in any way.</li>
              <li>Provide in a timely manner the information and documentation necessary to substantiate the various items of income and expense in question so that your Audit Representative(s) can prepare your defense.</li>
              <li>We ask that you comply with the Audit procedure and strategy actions recommended by <a href="http://ert.credit" target="_blank">ERT.Credit</a> and any of the Audit Representative(s) working on your behalf. If you are unable to maintain this commitment, ERT.Credit cannot be responsible for the outcome of your Audit and reserves the right to cease providing service if the audit exceeds the scope of the ERT.Credit work done on behalf of the Member.</li>
              <li>The <a href="http://ert.credit" target="_blank">ERT.Credit</a> audit defense agreement applies only to the work <a href="http://ert.credit" target="_blank">ERT.Credit</a> has done for you to claim the Employee Retention Credit. This agreement does not cover any other IRS audit activities related to you or your business.</li>
            </ol>
          </ul>
          <h3>AUDIT DEFENSE PLAN LIMITATIONS</h3>
          <p>ERT.Credit is dedicated solely to legitimately protecting the rights and assets of our members in the event of an Audit. The following defines our service limitations:</p>
          <ul>
            <li>ERT.Credit does not provide legal assistance, nor represent our members in Federal or State Court, including Tax Court.</li>
            <li>ERT.Credit does not provide legal assistance in defending issues of civil or criminal fraud, whether actual or alleged.</li>
            <li>ERT.Credit will not reconcile checkbooks, organize records or do record keeping or bookkeeping for our members.</li>
            <li>ERT.Credit does not provide assistance for collection notices when we have not defended the Audit. If you have a collection notice from the IRS, it is not considered an Audit and is not covered by your ERT.Credit Audit Defense Membership.</li>
            <li>ERT.Credit is not responsible for the extra work and costs involved if you report your Audit late or you do not cooperate by providing the documentation in a timely manner which results in the auditing agency issuing a Notice of Deficiency (NOD) or the state equivalent. If this occurs, ERT.Credit will pass these costs onto the member in the form of a late fee.</li>
          </ul>
          <h3>AUDIT DEFENSE PLAN EXCLUSIONS</h3>
          <p>Certain Audits, tax returns, and issues of audit may be excluded from the Audit Defense Plan for any of the following reasons:</p>
          <ul>
            <li><strong>Pre-existing Conditions</strong> &ndash; If the date on the notice of audit from the IRS or State is prior to the Acceptance Date of this Agreement, Audit Defense services for that audit are excluded.</li>
            <li><strong>Ownership Interest in Other Tax Entities</strong> &ndash; If you have an ownership interest in a Corporation, Partnership, LLP, Trust, Estate, or Tax Shelter that has been contacted for an Audit and is not an ERT.Credit member, Audit Defense services for that tax entity are excluded.</li>
            <li><strong>Tax Protests</strong> &ndash; ERT.Credit will exclude anyone protesting the taxing of income on economic, religious, legal or constitutional grounds, or other frivolous claims.</li>
            <li><strong>Criminal Investigation (CI)</strong> &ndash; If you are currently under investigation by CI, you are excluded from Audit Defense services. For any Audit that ERT.Credit is defending in which CI enters the Audit, ERT.Credit will cease working that Audit and will exclude the member from further Audit Defense services until completion of the CI investigation. When the CI investigation is completed, ERT.Credit will resume working on the Audit, if possible.</li>
            <li><strong>Other Taxes</strong> &ndash; This agreement excludes any audits or notices from the IRS or State that are not part of the ERC computations and amended returns provided by ERT.Credit.</li>
          </ul>
          <h3>TERMINATIONS OF MEMBERSHIP AGREEMENT</h3>
          <p>ERT.Credit reserves the right to terminate this Audit Defense Plan upon the breach of any material provision of this agreement by you, in the event that an Audit meets the criteria of any of the &ldquo;AUDIT DEFENSE PLAN EXCLUSIONS&rdquo; listed above, or in the event that a condition renders the completion of ERT.Credit&rsquo;s responsibilities under this agreement unreasonably difficult to fulfill. Conditions that can render completion of ERT.Credit responsibilities unreasonably difficult include, but are not limited to, failure by you to reasonably fulfill any provision listed as &ldquo;Your Responsibility&rdquo; under &ldquo;RESPONSIBILITIES&rdquo; above, failure to cooperate during the course of the Audit process, or repeated use of abusive, inappropriate, or unprofessional language when communicating with any staff members or representatives of ERT.Credit.</p>
          <p>This Audit Defense Plan shall also be deemed to be terminated if you did not pay the appropriate fee to ERT.Credit.</p>
          <h3>GOVERNING LAW:</h3>
          <p>Except as otherwise stated herein, this Agreement shall be governed by the laws of the State of California without regard to California&rsquo;s conflict of laws provisions thereof, to the extent such principles or rules would require or permit the application of the laws of any jurisdiction other than those of the State of California.</p>
          <p><strong>DISPUTES: YOU AND ERT.Credit AGREE TO RESOLVE ALL DISPUTES OR CLAIMS ARISING OUT OF OR RELATING IN ANY WAY TO THIS AGREEMENT AND THE SERVICES PROVIDED PURSUANT TO THIS AGREEMENT ONLY BY BINDING ARBITRATION OR IN SMALL CLAIMS COURT. YOU UNDERSTAND THAT BY ENTERING INTO THIS AGREEMENT, YOU ARE GIVING UP THE RIGHT TO BRING A CLAIM IN COURT OR IN FRONT OF A JURY. IN ADDITION, YOU AND ERT.Credit AGREE THAT ALL CLAIMS AND DISPUTES MUST BE BROUGHT IN AN INDIVIDUAL CAPACITY, AND NOT AS A CLASS, COLLECTIVE, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL ACTION. BY ENTERING INTO THIS AGREEMENT, YOU ACKNOWLEDGE THAT YOU HAVE VOLUNTARILY AND KNOWINGLY WAIVED ALL RIGHT TO LITIGATE OR PARTICIPATE IN A CLASS, COLLECTIVE, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL ACTION.</strong></p>
          <p>It is also agreed that: (1) except for claims which qualify for small claims court, the Federal Arbitration Act governs the interpretation and enforcement of this provision; (2) the arbitrator shall apply California law to all other matters associated with the dispute or claim; (3) the arbitration shall be conducted by the American Arbitration Association (AAA) before a single AAA arbitrator under AAA&rsquo;s Consumer Arbitration Rules (the Rules, fees, and procedures are available at <a href="http://www.adr.org" target="_blank" rel="noopener">www.adr.org</a> or may be obtained by calling 800-778- 7879); (4) you may choose to have the arbitration based only on documents submitted to the arbitrator or by hearing in person or by telephone; (5) in-person arbitration shall take place in (i) Sacramento, California; or, (ii) if the agreement to arbitrate in Sacramento, California would render this provision unenforceable, in-person arbitration shall take place in (a) any other location mutually agreed upon by the parties, including the county in which you live; or (b) if the parties are unable to agree, at a location set by the arbitrator; (6) notwithstanding any other provision of this Agreement, any party may at any time seek injunctions or other forms of equitable relief in arbitration or a court of competent jurisdiction; (7) the enforceability of this provision shall be decided by a court and not the arbitrator; (8) the decision of the arbitrator shall be final and binding on all parties, and judgment on the arbitration award may be entered in any court of competent jurisdiction; and (9) the arbitrator shall be entitled to award all damages and relief as would be available in court.</p>
          <p>If either party intends to initiate arbitration under this Agreement, the party seeking arbitration must first notify the other party of the dispute in writing at least thirty (30) days in advance of initiating arbitration. Notice to ERT.Credit must be sent to the ERT.Credit Dispute Resolution Manager, by filing a dispute at <a href="https://ert.credit/dispute" target="_blank" rel="noopener">https://ert.credit/dispute</a>. The notice must describe the nature of the claim or dispute and the relief being sought. If we are unable to resolve the dispute within sixty (60) days, either party may then proceed to file a claim for arbitration or a claim in small claims court. If you are unable to afford the arbitration filing fee and provide us with signed written notice of your inability to afford the filing fee, we will pay the fee directly to AAA. If arbitration proceeds, we will pay all other fees as required by the AAA Consumer Arbitration Rules. Each party shall bear his/her/its own attorneys&rsquo; fees and costs. If for any reason, the prohibition on class, collective, representative, or private attorney general actions is held to be unenforceable by a court of law, then the agreement to arbitrate will not apply to that dispute. If a claim proceeds through court rather than arbitration, YOU AND ERT.Credit AGREE TO WAIVE ANY RIGHT TO TRIAL BY JURY. This arbitration provision survives expiration, termination, or rescission of this agreement. Unenforceability or invalidity of one or more clauses in this arbitration provision shall not have an effect on any other clause in this provision. If it is possible, any unenforceable or invalid clause in this provision shall be modified to show the original intention of the parties.</p>
        </Container>
      </Section>

    </Layout>
  );
};

export default AuditDefenseProgram;
